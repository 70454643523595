import { create } from 'zustand'
import AuthService from "../services/auth.service";

interface UserRoles {
    SMS_ADMIN: boolean;
    LUNCH_ADMIN: boolean;
    SUPERADMIN: boolean;
    BESIEDKA_ADMIN: boolean;
    load: () => void;
}

const useUserRolesStore = create<UserRoles>((set, get) => ({
    SMS_ADMIN: false,
    LUNCH_ADMIN: false,
    SUPERADMIN: false,
    // TODO change me once bakcknd is implemented
    BESIEDKA_ADMIN: true,
    load: () => {
        const currentUser = AuthService.getCurrentUser();
        set({ SMS_ADMIN: currentUser?.roles?.includes("ROLE_SMS_ADMIN"), LUNCH_ADMIN: currentUser?.roles?.includes("ROLE_LUNCH_ADMIN"), SUPERADMIN: currentUser?.roles?.includes("ROLE_SUPERADMIN") });
    }
}))

export default useUserRolesStore;

