import axios from 'axios';
import Contact from '../types/Contact.type';
import SmsGroup from '../types/Smsgroup.type';
import authHeader from './auth-header';
const { REACT_APP_API_URL } = process.env;

const API_URL = REACT_APP_API_URL + 'sms/';

class SmsAdminService {
  deleteContact(contactId: Number) {
    return axios.delete(API_URL + 'delContact/' + contactId, { headers: authHeader() });
  }

  deleteSmsGroup(smsGroupId: Number) {
    return axios.delete(API_URL + 'delSmsGroup/' + smsGroupId, { headers: authHeader() });
  }

  getAllSmsGroups() {
    return axios.get(API_URL + 'getSmsGroups', { headers: authHeader() });
  }

  addUpdateContact(contact: Contact) {
    return axios.post(API_URL + 'addUpdateContact', contact, { headers: authHeader() });
  }

  addUpdateSmsGroup(smsGroup: SmsGroup) {
    return axios.post(API_URL + 'addUpdateSMSGroup', smsGroup, { headers: authHeader() });
  }

  searchContacts(searchQuery: string) {
    return axios.post(API_URL + 'searchContacts', {searchQuery: searchQuery}, { headers: authHeader() });
  }

    // /**
  //  * Send message to single phone number, return true if message was sent correctly.
  //  * @param message 
  //  * @param phoneNumber 
  //  * @returns boolean
  //  */
  // sendOneSms(message: string, phoneNumber: string) {
  //   return axios.post(API_URL + 'sendOneSms', { message: message, phoneNumber: phoneNumber }, { headers: authHeader() });
  // }

  /**
   * Sends message to all members of the group, TODO should return list of contacts to whom sms was no sent due to some reason - with reason
   * @param message 
   * @param smsGroupId 
   * @returns 
   */
  sendSmsToGroup(message: string, smsGroupId: Number) {
    return axios.post(API_URL + 'sendSmsToGroup', { message, smsGroupId }, { headers: authHeader() });
  }

  runManualImport(){
    return axios.post(API_URL + 'runManualImport', {}, { headers: authHeader() });
  }

}

export default new SmsAdminService();
