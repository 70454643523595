import axios from 'axios';
import authHeader from './auth-header';
import { LunchOrderInterface } from "../types/Lunch.type";
import { PassChangeReq } from '../types/passChange.type';

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + 'user/';

export const orderLunch = (lunchOrder: LunchOrderInterface) => {
    return axios.post(API_URL + 'orderLunch', lunchOrder, { headers: authHeader() });
}
export const getLunchesCanOrder = () => {
    return axios.get(API_URL + 'getLunchesCanOrder', { headers: authHeader() });
}

export const getOrderedLunches = () => {
    return axios.get(API_URL + 'getOrderedLunches', { headers: authHeader() });
}

export const deleteOrderedLunch = (orderId: number) => {
    return axios.delete(API_URL + 'deleteOrderedLunch/' + orderId, { headers: authHeader() });
}

export const changePassword = (passChangeReq: PassChangeReq) => {
    return axios.post(API_URL + 'changePass', passChangeReq, { headers: authHeader() });
}
// class UserService {
// orderLunch(lunchOrder: LunchOrderInterface) {
//     return axios.post(API_URL + 'orderLunch', lunchOrder, { headers: authHeader() });
// }

// getLunchesCanOrder() {
//     return axios.get(API_URL + 'getLunchesCanOrder', { headers: authHeader() });
// }

// getOrderedLunches() {
//     return axios.get(API_URL + 'getOrderedLunches', { headers: authHeader() });
// }

// deleteOrderedLunch(orderId: number) {
//     return axios.delete(API_URL + 'deleteOrderedLunch/' + orderId, { headers: authHeader() });
// }

// changePassword(passChangeReq: PassChangeReq) {
//     return axios.post(API_URL + 'changePass', passChangeReq, { headers: authHeader() });
// }
// }

// export default new UserService();