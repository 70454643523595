import AuthService from "../services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import IUser from "../types/user.type";
import useUserRolesStore from "../stores/userRoles.store";
import { useTranslation } from "react-i18next";
import useSettingsStore from "../stores/settings.store";

function UserDashboard() {
  const [currentUser, setCurrentUser] = useState<IUser | undefined>(AuthService.getCurrentUser());
  const [showSmsBoard, showLunchBoard, showAdminBoard, showBesiedkaAdminBoard] = useUserRolesStore(state => [state.SMS_ADMIN, state.LUNCH_ADMIN, state.SUPERADMIN, state.BESIEDKA_ADMIN]);
  const [lunch_module_enabled, sms_module_enabled] = useSettingsStore(state => [state.isSettingEnabled('Lunch_module_enabled'), state.isSettingEnabled('Sms_module_enabled')]);
  const { t } = useTranslation();

  let navigate = useNavigate();

  useEffect(() => {
    if (null == currentUser) { navigate("/") };
  });

  return (
    <div className="home-page">
      {/* riadok #1 */}
      <div className="row">
        {lunch_module_enabled && <Link to={"/obed"} className="nav-link col-sm text-centered">
          <i className="col-sm menuIcon bi bi-tsunami"></i><div>{t('order-lunch')}</div>
        </Link>
        }

        <Link to={"/profile"} className="nav-link col-sm text-centered">
          <i className="col-sm menuIcon bi bi-check2-square"></i><div>{t('profile')}</div>
        </Link>

        {sms_module_enabled && showSmsBoard && (
          <Link to={"/smsadmin"} className="nav-link col-sm text-centered">
            <i className="col-sm menuIcon bi bi-chat-dots"></i><div>{t('sms-board')}</div>
          </Link>
        )}

        {lunch_module_enabled && showLunchBoard && (
          <Link to={"/obedadmin"} className="nav-link col-sm text-centered">
            <i className="col-sm menuIcon bi bi-egg-fried"></i><div>{t('insert-lunch')}</div>
          </Link>
        )}

        {showAdminBoard && (
          <Link to={"/admin-board"} className="nav-link col-sm text-centered">
            <i className="col-sm menuIcon bi bi-fingerprint"></i><div>{t('admin-board')}</div>
          </Link>
        )}

        {/* TODO change icon for besiedka admin */}
        {showBesiedkaAdminBoard && (
          <Link to={"/besiedka-admin"} className="nav-link col-sm text-centered">
            <i className="col-sm menuIcon bi bi-fingerprint"></i><div>{t('besiedka-admin-board')}</div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default UserDashboard;