import { Component } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

type Props = { dateSelected: any, className?: string, startDate?: Date };

type State = {
    startDate: Date;
}

export class MyCustomCalendar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        let startDate = this.props.startDate !== undefined ? this.props.startDate : new Date();
        this.state = {
            startDate: startDate
        };
        this.props.dateSelected(startDate);
    }

    componentDidMount() {
    }

    render() {
        const { startDate } = this.state;
        const years = [startDate.getFullYear(), startDate.getFullYear() + 1, startDate.getFullYear() + 2];

        const months = [
            "Január",
            "Február",
            "Marec",
            "Apríl",
            "Máj",
            "Jún",
            "Júl",
            "August",
            "September",
            "Október",
            "November",
            "December",
        ];

        return (
            <DatePicker
                className={this.props.className}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="dd-MM-yyyy, HH:mm"
                calendarStartDay={1}
                showWeekNumbers
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div
                        style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                        </button>

                        <select
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) => changeYear((value as unknown) as number)}
                        >
                            {years.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <select
                            value={months[date.getMonth()]}
                            onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                            }
                        >
                            {months.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                        </button>
                    </div>
                )}

                selected={startDate} onChange={(date: Date) => { this.setState({ startDate: date }); this.props.dateSelected(date); }} />
        );
    }
}
