import { Component } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Contact from "../types/Contact.type";
import SmsGroup from "../types/Smsgroup.type";
import SmsAdminService from "../services/smsAdmin.service";
import Multiselect from "multiselect-react-dropdown";
import EventBus from "../common/EventBus";
import * as Yup from "yup";
import { toast } from "react-toastify";

type Props = { contact: Contact | undefined, closeCreateEditPanel: any, smsGroups: Array<SmsGroup>, updateSearchResults: any };

type State = {
    currentContact: Contact | undefined;
    selectedSmsGroups: Array<SmsGroup>;
    editSaveContactResponse: string;
    loading: boolean;
}

export default class CreateEditContact extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.closeCreateEditPanel = this.closeCreateEditPanel.bind(this);
        this.handleSaveContact = this.handleSaveContact.bind(this);
        this.smsGroupRemoved = this.smsGroupRemoved.bind(this);
        this.smsGroupSelected = this.smsGroupSelected.bind(this);

        this.state = {
            currentContact: this.props.contact,
            selectedSmsGroups: this.props.contact?.smsgroups || [],
            editSaveContactResponse: "",
            loading: false,
        };
    }

    componentDidMount() {

    }

    closeCreateEditPanel(resultStatus?: boolean) {
        this.props.closeCreateEditPanel(resultStatus);
    }

    handleSaveContact(input: any) {
        const { firstname, lastname, email, phone } = input;
        // TODO implement not firing this function by hitting enter

        if (this.state.selectedSmsGroups.length === 0) {
            return;
        }

        const contactToSend = {
            id: this.props.contact?.id ?? undefined,
            firstname: firstname.trim().length > 0 ? firstname : undefined,
            lastname: lastname.trim().length > 0 ? lastname : undefined,
            email: email.trim().length > 0 ? email : undefined,
            phone: phone.trim().length > 4 ? phone : undefined,
            smsgroups: this.state.selectedSmsGroups,
            sheetsId: this.props.contact?.sheetsId ?? undefined
        } as Contact;

        // this means that no value is filled in the contact details.
        if (Object.values(contactToSend).filter(function (element) {
            return element !== undefined;
        }).length < 2) {
            return;
        }

        this.setState({ loading: true });

        SmsAdminService.addUpdateContact(contactToSend).then(
            response => {
                this.setState({ editSaveContactResponse: response.data?.message, loading: false });
                // TODO show some toast message ?
                toast.success(response.data?.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // here to update main search query
                this.props.updateSearchResults();
                this.closeCreateEditPanel(true);
            },
            error => {
                this.setState({
                    loading: false,
                    editSaveContactResponse:
                        (error.response != null ? error.response.status + ' ' + error.response.data : error.toString())
                });

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }

    smsGroupSelected(smsGroup: SmsGroup) {
        if (this.state.selectedSmsGroups === undefined) {
            this.setState({ selectedSmsGroups: new Array<SmsGroup>() });
        }

        this.setState({ selectedSmsGroups: [...this.state.selectedSmsGroups, smsGroup] })
    }

    smsGroupRemoved(smsGroup: SmsGroup) {
        this.setState({ selectedSmsGroups: this.state.selectedSmsGroups?.filter(obj => obj !== smsGroup) });
    }

    validationSchema() {
        return Yup.object().shape({
            firstname: Yup.string().nullable(true).min(3, "Min 3 chars").max(40, "Max 40 chars."),
            lastname: Yup.string().nullable(true).min(3, "Min 3 chars").max(40, "Max 40 chars."),
            email: Yup.string().nullable(true).email("Email is not valid"),
            phone: Yup.string().nullable(true).transform(e => e.replace(/\s/g, '')).matches(/^\+[0-9]{12}$/, "Phone not in correct format")
        });
    }

    render() {
        const { loading, selectedSmsGroups, currentContact, editSaveContactResponse } = this.state;

        let initialValues = {
            firstname: currentContact?.firstname ?? "",
            lastname: currentContact?.lastname ?? "",
            email: currentContact?.email ?? "",
            phone: currentContact?.phone ?? "+421",
            smsgroups: selectedSmsGroups
        };

        return (
            <div>
                {currentContact?.id ? "Edit" : "Create"} contact
                <Formik
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={(e) => { this.handleSaveContact(e) }}
                >
                    <Form onKeyUp={event => { if (event.key === 'Enter') event.preventDefault(); }}
                    >
                        <div className="form-group">
                            <label htmlFor="firstname">Firstname</label>
                            <Field name="firstname" type="text" className="form-control" />
                            <ErrorMessage
                                name="firstname"
                                component="div"
                                className="alert alert-danger"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="lastname">Lastname</label>
                            <Field name="lastname" type="text" className="form-control" />
                            <ErrorMessage
                                name="lastname"
                                component="div"
                                className="alert alert-danger"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <Field name="email" type="text" className="form-control" />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="alert alert-danger"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <Field name="phone" type="text" className="form-control" />
                            <ErrorMessage
                                name="phone"
                                component="div"
                                className="alert alert-danger"
                            />
                        </div>

                        <label htmlFor="smsGroups">Sms groups</label>
                        <Multiselect
                            options={this.props.smsGroups} // Options to display in the dropdown
                            selectedValues={this.state.selectedSmsGroups} // Preselected value to persist in dropdown
                            onSelect={(list, item) => (this.smsGroupSelected(item as SmsGroup))} // Function will trigger on select event
                            onRemove={(list, item) => (this.smsGroupRemoved(item as SmsGroup))} // Function will trigger on remove event
                            displayValue="smsgroup" // Property name to display in the dropdown options
                            placeholder="Sms groups"
                        />


                        <div className="form-group">
                            <button type="submit" className="btn btn-success btn-block">
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Save</span>
                            </button>
                            <button onClick={() => this.closeCreateEditPanel()} className="btn btn-danger btn-block">

                                <span>Close</span>
                            </button>

                        </div>

                        {editSaveContactResponse && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {editSaveContactResponse}
                                </div>
                            </div>
                        )}
                    </Form>
                </Formik>
            </div>
        );
    }
}
