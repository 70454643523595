import axios from 'axios';

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + 'public/';

class UnathenticatedService {
    getSignUpList(){
        return axios.get(API_URL + 'getSignUpList');
    }
    signUpToMailingList(mailingListSignUpRequest: any) {
        return axios.post(API_URL + 'signUpToMailingList', mailingListSignUpRequest);
    }
    
    // deleteOrderedLunch(orderId: number){
    //     return axios.delete(API_URL + 'deleteOrderedLunch/'+orderId);
    // }
}

export default new UnathenticatedService();