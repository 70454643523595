import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import IUser from "../types/user.type";
import useUserRolesStore from "../stores/userRoles.store";
import { useTranslation } from "react-i18next";
import useSettingsStore from "../stores/settings.store";
import AddRoleToUser from "./addRoleToUser.component";
import ManageAppSettings from "./manageAppSettings.component";

const AdminBoard = () => {
    const [currentUser, setCurrentUser] = useState<IUser | undefined>(AuthService.getCurrentUser());
    const [showAdminBoard] = useUserRolesStore(state => [state.SUPERADMIN]);
    const { t } = useTranslation();

    let navigate = useNavigate();

    useEffect(() => {
        if (null == currentUser) { navigate("/") };
    });

    return (
        <div className="admin-board">
            <div className="row">
                {showAdminBoard && (
                    <>
                        <AddRoleToUser />
                        <hr />
                        <ManageAppSettings />
                    </>
                )}
            </div>
        </div>
    );
};

export default AdminBoard;