import axios, { AxiosResponse } from 'axios';
import authHeader from './auth-header';
import { configSettings } from '../types/settings/Settings.type';

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + 'admin/';

export const addRoleToUser = (userId: number, roleId: number) => {
    return axios.post(API_URL + 'addRoleToUser', { userId: userId, roleId: roleId }, { headers: authHeader() });
}

export const getUsers = () => {
    return axios.get(API_URL + 'getUsers', { headers: authHeader() });
}

export const getRoles = () => {
    return axios.get(API_URL + 'getRoles', { headers: authHeader() });
}

export const getSettings = (): Promise<AxiosResponse<configSettings[], any>> => {
    return axios.get(API_URL + 'getSettings', { headers: authHeader() });
}

export const setSetting = (settings: configSettings[]) => {
    return axios.post(API_URL + 'setSettings', settings, { headers: authHeader() });
}