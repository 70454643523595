import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthService from "../services/auth.service";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';

function Register() {
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [successfull, setsuccessfull] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    let navigate = useNavigate();

    let onRecaptchaChange = (value: string | null) => {
        if (value) {
            setCaptchaToken(value);
        }
    }

    let handleRegister = (formValue: { email: string; password: string }) => {
        const { email, password } = formValue;

        if (null === captchaToken) {
            return;
        }

        setMessage("");
        setsuccessfull(false);


        AuthService.register(
            email,
            password,
            captchaToken
        ).then(
            async (response: { data: { message: any; }; }) => {
                setMessage(response.data.message);
                setsuccessfull(true);
                await new Promise(f => setTimeout(f, 4000));
                return navigate("/");
            },
            (error: { response: { data: { message: any; }; }; message: any; toString: () => any; }) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setMessage(resMessage);
                setsuccessfull(false);
            }
        ).finally(() => { setCaptchaToken(null) });
    }

    let validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string()
                .email("Nesprávny formát emailu.")
                .required("Povinné pole!"),
            password: Yup.string()
                .test(
                    "len",
                    "Min 8, max 40 znakov.",
                    (val: any) =>
                        val &&
                        val.toString().length >= 8 &&
                        val.toString().length <= 40
                )
                .required("Povinné pole!"),
        });
    }

    return (
        <div className="col-md-12">
            <div className="card card-container">
                <img
                    src="/favicons/favicon-192x192.png"
                    alt="Milost"
                    className="milost-login-logo"
                />
                <h2 className="text-centered">Registrácia</h2>
                <i className="bi bi-info-circle">
                    Len pre e-mail adresy s vyjadreným <a href="https://forms.gle/j2GkyALxJSdh4kxdA" target="_blank" rel="noreferrer">súhlasom s GDPR.</a>
                </i>

                <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleRegister}
                >
                    <Form>
                        {!successfull && (
                            <div>
                                <div className="form-group">
                                    <label htmlFor="email"> Email </label>
                                    <Field name="email" type="email" className="form-control" />
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password"> Heslo </label>
                                    <Field
                                        name="password"
                                        type="password"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                </div>
                                <ReCAPTCHA
                                    className="captcha"
                                    sitekey="6LdqNXUfAAAAAFV6iX2pVeLy5q_so2tKsdbCXF6Y"
                                    onChange={onRecaptchaChange}
                                    onExpired={() => { setCaptchaToken(null) }}
                                    size="compact"
                                />
                                <div className="form-group">

                                    <button disabled={null === captchaToken} type="submit" className="btn btn-primary btn-block">Zaregistrovať</button>
                                </div>
                            </div>
                        )}

                        {message && (
                            <div className="form-group">
                                <div
                                    className={successfull ? "alert alert-success" : "alert alert-danger"}
                                    role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                    </Form>
                </Formik>
                <a className="text-centered" href="/">Prihlásenie</a>
            </div>
        </div>
    );
};

export default Register;