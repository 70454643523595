import AuthService from "../services/auth.service";
import IUser from "../types/user.type";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import ChangePassword from "../components-user/changePassword.component";
import { useTranslation } from "react-i18next";

function Profile() {
  const [currentUser] = useState<IUser & { accessToken: string }>(AuthService.getCurrentUser());
  let navigate = useNavigate();
  const { t } = useTranslation();
  // console.log("Profile");

  useEffect(() => {
    if (currentUser === null || currentUser === undefined) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  return (
    <div className="container">
      {currentUser ?
        <div>
          <header>
            <h3>
              {t('username')}: <strong>{currentUser.username}</strong>
            </h3>
          </header>
          <strong>{t('registered-roles')}:</strong>
          <ul>
            {currentUser.roles &&
              currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
          </ul>

          <ChangePassword />
        </div> : null}
    </div>
  );
};

export default Profile;