import { create } from 'zustand'
import { getSettings, setSetting } from '../services/admin.service';
import { configSettings } from '../types/settings/Settings.type';
import AuthService from "../services/auth.service";

interface Settings {
    settings: configSettings[];
    load: () => void;
    isSettingEnabled: (name: string) => boolean;
    setSetting: (name: string, value: string) => void;
    saveSettings: () => void;
}

const useSettingsStore = create<Settings>((set, get) => ({
    settings: [],
    load: () => {
        if (get().settings.length === 0 && null !== AuthService.getCurrentUser()) {
            getSettings().then(response => {
                console.debug("Setting appSettings");
                set({ settings: response.data });
            })
        }
    },
    isSettingEnabled: (name: string) => {
        var found = get().settings.find(item => item.name === name);
        if (found) {
            return found.value === 'true';
        }
        return false;
    },
    setSetting: (name: string, value: string) => {
        var found = get().settings.find(item => item.name === name);
        if (found) {
            let settings = get().settings;
            const indexOfFound = settings.indexOf(found);
            found.value = value;
            settings[indexOfFound] = found;
            set({ settings: settings })
        }
    },
    saveSettings: () => {
        setSetting(get().settings).then(response => {
            // TODO dorobit
            console.warn("saveSettings");
            console.warn(response);
        })
    }
}))

export default useSettingsStore;

