import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import React, { useEffect, useState } from 'react';
import EventBus from "../common/EventBus";
import useSettingsStore from "../stores/settings.store";

function Login() {
  // const [username, setUsername] = useState<string>("");
  // const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [mailing_list_enabled] = useSettingsStore(state => [state.isSettingEnabled('Mailing_list_enabled')]);
  // console.log("Login");

  let navigate = useNavigate();


  useEffect(() => {
    if (null != AuthService.getCurrentUser()) {
      //user is logged in, redirect somewhere esle
      console.log("redirecting");
      // window.location.reload()

      // navigate("/profile");
    }
  });

  let validationSchema = () => {
    return Yup.object().shape({
      //TODO add minimal lengths for both
      username: Yup.string().required("This field is required!"),
      password: Yup.string().required("This field is required!"),
    });
  }

  // let handleGoogleResponse = (response: any) => {
  //   console.log(response);
  //   // AuthService.googleLogin().then();
  // }

  let handleLogin = (formValue: { username: string; password: string }) => {
    const { username, password } = formValue;

    setMessage("");
    setLoading(true);

    AuthService.login(username, password).then(
      (response: { roles: string | string[]; }) => {
        EventBus.dispatch("login");

        console.log("navigating to /");
        navigate("/");
      },
      (error: { response: { data: { message: any; }; }; message: any; toString: () => any; }) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
        setLoading(false);
      }
    );
  }

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="/favicons/favicon-192x192.png"
          alt="Milost"
          className="milost-login-logo"
        />
        <h2 className="text-centered">Prihlásenie</h2>
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          <Form>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <Field name="username" type="text" className="form-control" />
              <ErrorMessage
                name="username"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field name="password" type="password" className="form-control" autoComplete="on" />
              <ErrorMessage
                name="password"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Formik>
        <a className="text-centered" href="/join-us">Registrácia</a>
      </div>
      {mailing_list_enabled && (
        <div className="row">
          <Link to={"/signup"} className="nav-link offset-sm-4 col-sm-2 text-centered">
            <i className="col-sm menuIcon bi bi-mailbox2"></i><div>Mailing list</div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Login;