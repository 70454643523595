import {
    Dialog, DialogTitle, DialogContent,
    DialogActions, Button, Box,
    IconButton, Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Component } from "react";

type Props = { data: ConfirmDialogData };

export type ConfirmDialogData = {
    body: string,
    title: string,
    onSubmit?: any,
    onClose: any,
}

export default class ConfirmDialog extends Component<Props> {

    render() {
        const { body, title, onClose, onSubmit } = this.props.data;

        return (this.props.data.body?.length > 0) && (
            <Dialog open={Boolean(onSubmit)} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>{title}</DialogTitle>
                <Box position="absolute" top={0} right={0}>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
                <DialogContent>
                    <Typography>{body}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button color="secondary" variant="contained" onClick={() => {
                        if (onSubmit) {
                            onSubmit();
                        }
                        onClose();
                    }}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export class MessageDialog extends Component<Props> {

    render() {
        const { body, title, onClose, onSubmit } = this.props?.data;

        return (this.props.data.body?.length > 0) && (
            <Dialog open={Boolean(onSubmit)} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>{title}</DialogTitle>
                <Box position="absolute" top={0} right={0}>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
                <DialogContent>
                    <Typography>{body}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" variant="contained" onClick={() => {
                        onClose();
                    }}>
                        Zavrieť
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}