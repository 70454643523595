import axios from 'axios';
import authHeader from './auth-header';
import { LunchEntry } from "../types/Lunch.type";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + 'obed/';

class LunchAdminService {
    addLunchEntry(lunchEntry: LunchEntry) {
        return axios.post(API_URL + 'addLunchEntry', lunchEntry, { headers: authHeader() });
    }

    getActualLunchWithInfo() {
        return axios.get(API_URL + 'getActualLunchInfo', { headers: authHeader() });
    }

    getHistoryLunchEntries() {        
        return axios.get(API_URL + 'getHistoryLunchEntries', { headers: authHeader() });
    }

    inactivateActualLunch(lunchEntryId: number) {
        return axios.delete(API_URL + 'inactivateLunchEntry/' + lunchEntryId, { headers: authHeader() });
    }

    deleteLunchEntry(lunchEntryId: number) {
        return axios.delete(API_URL + 'deleteLunchEntry/' + lunchEntryId, { headers: authHeader() });
    }
    
}

export default new LunchAdminService();