import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { changePassword } from "../services/user.service";
import { PassChangeReq } from "../types/passChange.type";
import delay from "../common/delay";
import { useTranslation } from "react-i18next";

function ChangePassword() {
    const [newPassword, setNewPassword] = useState("");
    const [responseMessage, setResponseMessage] = useState("");
    const [responseSuccess, setResponseSuccess] = useState<boolean>();
    let navigate = useNavigate();
    const { t } = useTranslation();


    useEffect(() => {
        if (null == AuthService.getCurrentUser()) navigate("/");
    });

    let changeUserPassword = () => {
        if (newPassword == null || newPassword.length < 7) {
            return;
        }

        let changeReq = {
            newPassword: newPassword
        } as PassChangeReq;

        changePassword(changeReq).then(
            response => {
                setResponseMessage(response.data);
                setResponseSuccess(true);
                console.log(response.data);
            },
            error => {
                setResponseMessage("Nastala chyba: " + error);
                setResponseSuccess(false);
            }
        ).finally(() => {
            // wait for few seconds, then hide message
            delay(5000).then(() => setResponseMessage(""));
            // null values
            setNewPassword("");
        });
    }

    return (
        <>
            <div>
                <h4>{t('change-password')}:</h4>
                <div>
                    <TextField value={newPassword} onChange={(event) => setNewPassword(event.target.value)} type='password' id="new-pass" label={t('new-password')} variant="outlined" />
                </div>
                <div>
                    <Button onClick={changeUserPassword} variant="outlined">{t('save')}</Button>
                </div>
            </div>
            {responseMessage && (
                <div className={responseSuccess ? "col-sm-3 bg-success" : "col-sm-3 bg-danger"}>
                    {responseMessage}
                </div>
            )}
        </>
    );
};

export default ChangePassword;