import axios from "axios";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + 'auth/';

class AuthService {
  login(username: string, password: string) {
    return axios
      .post(API_URL + "login", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  register(email: string, password: string, captchaToken: string) {
    return axios.post(API_URL + "signup", { email, password, captchaToken });
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser = () => {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);

    return null;
  }
}

export default new AuthService();
