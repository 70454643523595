import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from "react";
import EventBus from "../../common/EventBus";
import { deleteOrderedLunch, getLunchesCanOrder, getOrderedLunches, orderLunch } from "../../services/user.service";
import { CustomerOrderLight, LunchEntry, LunchOrderInterface, } from "../../types/Lunch.type";
import ConfirmDialog from "../../components-common/confirm-dialog";
import { ConfirmDialogData } from "../../types/ConfirmDialogData.type";
import AuthService from "../../services/auth.service";
import IUser from "../../types/user.type";
import { useNavigate } from "react-router-dom";

let lunchEntryDropDownRef: any;
let lunchCountRef: any;

function LunchOrder() {
  const [currentUser] = useState<IUser & { accessToken: string }>(AuthService.getCurrentUser());
  const [lunchesCanOrder, setLunchesCanOrder] = useState<Array<LunchEntry>>([]);
  const [lunchOrderErrorText, setLunchOrderErrorText] = useState<string>("");
  const [lunchOrder, setLunchOrder] = useState<LunchOrderInterface>({} as LunchOrderInterface);
  const [orderedLunches, setOrderedLunches] = useState<Array<CustomerOrderLight> | undefined>(undefined);
  const [confirmationDialogData, setConfirmationDialogData] = useState<ConfirmDialogData>({} as ConfirmDialogData);

  let navigate = useNavigate();

  useEffect(() => {
    console.log("check if user is logged in: " + currentUser);
    if (currentUser === null || currentUser === undefined) {
      console.warn("before navigate");
      navigate("/");
    }
  }, [currentUser, navigate]);

  let logoutOnUnauthorized = (error: any) => {
    if (error.response && error.response.status === 401) {
      console.log("logou event dispatch");
      EventBus.dispatch("logout");
    }
  }

  let LgetLunchesCanOrder = () => {
    getLunchesCanOrder().then(
      (response: { data: LunchEntry[]; }) => {
        setLunchesCanOrder(response.data as Array<LunchEntry>);
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        setLunchOrderErrorText((error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString());
        logoutOnUnauthorized(error);
      }
    )
  }

  let LgetOrderedLunches = () => {
    getOrderedLunches().then(
      (response: { data: any; }) => {
        setOrderedLunches(response.data);
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        setLunchOrderErrorText((error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString());
        logoutOnUnauthorized(error);
      }
    )
  }

  let LorderLunch = () => {
    orderLunch(lunchOrder).then(
      (response: any) => {
        setLunchOrder({} as LunchOrderInterface);

        lunchEntryDropDownRef.value = undefined;
        lunchCountRef.value = undefined;
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        setLunchOrderErrorText((error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString());
        logoutOnUnauthorized(error);
      }
    ).finally(LgetOrderedLunches);
  }

  let LdeleteOrderedLunch = (orderId: number) => {
    deleteOrderedLunch(orderId).then(
      (response: any) => {
        LgetOrderedLunches();
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        setLunchOrderErrorText((error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString());
        logoutOnUnauthorized(error);
      }
    );
  }

  useEffect(() => {
    LgetLunchesCanOrder();
    LgetOrderedLunches();
  }, []);

  return (
    <>
      {currentUser && (
        <div className="container lunch-order-area lunch-picture">
          Objednávanie obedov:
          <FormControl fullWidth>
            <InputLabel className="lunch-select" id="select-label">Výber z obedov</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              ref={el => lunchEntryDropDownRef = el}
              label="Age"
              value={lunchOrder?.lunchEntryId || ''}
              onChange={(event) => {
                setLunchOrder({ ...lunchOrder, lunchEntryId: event.target.value as number });
              }}
            >
              {lunchesCanOrder && lunchesCanOrder.map((row, index) => {
                return (
                  <MenuItem key={index} value={row.id}>{"".concat(new Date(row.dateOfLunch).toLocaleString("sk"), " ", row.lunchEntryText, " ", (row.lunchPrice / 100) + " €")}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl>
            <Input
              ref={el => lunchCountRef = el}
              type="number"
              endAdornment={<InputAdornment position="end">ks</InputAdornment>}
              aria-describedby="lunch-count"
              value={lunchOrder?.lunchCount || ''}
              onChange={(event) => {
                setLunchOrder({ ...lunchOrder, lunchCount: +event.target.value });
              }}
              inputProps={{
                'aria-label': 'lunch-count',
              }}
            />
            <FormHelperText id="lunch-count">Počet obedov</FormHelperText>
          </FormControl>
          <button onClick={LorderLunch}>Objednať!</button>

          <br />
          <br />
          <hr />
          <div>Vaše objednané obedy:</div>
          <div>

            {orderedLunches && orderedLunches.map((row, index) => {
              return (
                <div key={index}>
                  <span  >{new Date(row.dateOfLunch).toLocaleString("sk")} {row.lunchOrdertext}: {row.lunchCount} ks</span>
                  <button
                    onClick={() => setConfirmationDialogData({
                      title: "Vymazanie objednaného obedu",
                      body: "Kliknutím potvrďte vymazanie.",
                      onClose: () => setConfirmationDialogData({} as ConfirmDialogData),
                      onSubmit: () => LdeleteOrderedLunch(row.customerOrderId)
                    })
                    }
                    className="btn btn-outline-danger">
                    Vymazať
                  </button>
                </div>
              )
            })}
          </div>
          <ConfirmDialog data={confirmationDialogData} />
          {lunchOrderErrorText}
        </div >
      )}
    </>
  );
};

export default LunchOrder;