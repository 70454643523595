import { Component } from "react";
import { userRole } from "../types/role.type";
import IUser from "../types/user.type";
import EventBus from "../common/EventBus";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import AuthService from "../services/auth.service";
import { addRoleToUser, getRoles, getUsers } from "../services/admin.service";
import { useTranslation } from "react-i18next";

type Props = {};

type State = {
  users: Array<IUser> | undefined,
  roles: Array<userRole> | undefined,
  errorText: string,
  pickedUserId: number | undefined,
  pickedRoleId: number | undefined,
  addRoleToUserbuttonDisabled: boolean,
  currentUser: IUser | undefined,
}

let logoutOnUnauthorized = (error: any) => {
  if (error.response && error.response.status === 401) {
    EventBus.dispatch("logout");
  }
}

export default class AddRoleToUser extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.getUsers = this.getUsers.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.addRoleToUser = this.addRoleToUser.bind(this);

    this.state = {
      users: undefined,
      roles: undefined,
      errorText: "",
      pickedUserId: undefined,
      pickedRoleId: undefined,
      addRoleToUserbuttonDisabled: false,
      currentUser: AuthService.getCurrentUser(),
    };
  }

  componentDidMount() {
    this.getUsers();
    this.getRoles();
  }

  getUsers() {
    getUsers().then(
      (response: { data: IUser[]; }) => {
        this.setState({ users: response.data as Array<IUser> });
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        this.setState({
          errorText:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
        logoutOnUnauthorized(error);
      }
    )
  }
  getRoles() {
    getRoles().then(
      (response: { data: userRole[]; }) => {
        this.setState({ roles: response.data as Array<userRole> });
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        this.setState({
          errorText:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
        logoutOnUnauthorized(error);
      }
    )
  }

  addRoleToUser() {
    if (!this.state.pickedRoleId || !this.state.pickedUserId) {
      return;
    }
    this.setState({ addRoleToUserbuttonDisabled: true });
    addRoleToUser(this.state.pickedUserId, this.state.pickedRoleId).then(
      (response: any) => {
        //here to empty dropdown boxes
        this.setState({ pickedRoleId: undefined, pickedUserId: undefined, addRoleToUserbuttonDisabled: false });
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        this.setState({
          errorText:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
        logoutOnUnauthorized(error);
      }
    )
  }

  rolePickerRef: any;
  userPickerRef: any;


  render() {
    const { currentUser, users, pickedUserId, roles, pickedRoleId } = this.state;
    // const { t } = useTranslation();

    return (
      <>
        {currentUser && (<>


          <div className=" red-bordered-area container">
            Add role to user:
            <FormControl fullWidth>
              <InputLabel className="lunch-select" id="select-label">User</InputLabel>
              <Select
                labelId="select-label"
                id="simple-select"
                ref={el => this.userPickerRef = el}
                label="Age"
                value={pickedUserId || ""}
                onChange={(event) => {
                  this.setState({ pickedUserId: event.target.value as number });
                }}
              >
                {users && users.map((row, index) => {
                  return (
                    <MenuItem key={index} value={row.id}>{row.email ?? ""}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel className="lunch-select" id="select-label">Role</InputLabel>
              <Select
                labelId="select-label"
                id="simple-select"
                ref={el => this.rolePickerRef = el}
                label="Age"
                value={pickedRoleId || ""}
                onChange={(event) => {
                  this.setState({ pickedRoleId: event.target.value as number });
                }}
              >
                {roles && roles.map((row, index) => {
                  return (
                    <MenuItem key={index} value={row.id}>{"".concat(row.friendlyName, " ", row.name, " ", row.id + "")}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>

            <button onClick={this.addRoleToUser}>Add role to user</button>
          </div>
        </>
        )}
      </>
    );
  }
}
