import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialogData, MessageDialog } from '../components-common/confirm-dialog';
import UnathenticatedService from '../services/unathenticated.service'
import IdName from '../types/public/IdName.type';

function SignUpPage() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("@");
    const [signUpList, setSignUpList] = useState<IdName[]>();
    const [userPickedEvents, setUserPickedEvents] = useState<IdName[]>();
    const [reCaptchaToken, setReCaptchaToken] = useState("");
    const [clickButtonDisabled, setClickButtonDisabled] = useState(true);
    const [confirmationDialogData, setConfirmationDialogData] = useState<ConfirmDialogData>({} as ConfirmDialogData);
    let navigate = useNavigate();

    let saveContact = () => {
        let signUpRequest = {
            name: name,
            email: email,
            signedUpListItems: userPickedEvents,
            reCaptchaToken: reCaptchaToken
        };

        // make button disabled
        setClickButtonDisabled(true);
        UnathenticatedService.signUpToMailingList(signUpRequest).then(response => {
            // show confirmation dialog after receiving callback confirm
            console.log(response.data);
            setConfirmationDialogData({
                title: "Úspešne ste sa prihlásili!",
                body: "Prihlásili ste sa k odberu informácií.",
                onClose: () => { setConfirmationDialogData({} as ConfirmDialogData); },
                onSubmit: () => { navigate("/intranet"); }
            });
        });
        // enable button
        setClickButtonDisabled(false);

        // vsetko vynulovat
        setName("");
        setEmail("@");
        setUserPickedEvents(signUpList);
        // TODO null checkboxes

    }

    useEffect(() => {
        UnathenticatedService.getSignUpList().then((response) => {
            setSignUpList(response.data);
            setUserPickedEvents(response.data);
        })
    }, []);

    return (
        <>
            <div className='offset-md-3 col-md-6'>
                <h3>Pridaj sa k mailing listu!</h3>
                <div>
                    <div>Meno:</div>
                    <TextField required value={name} onChange={(event) => setName(event.target.value)} id="firstname" label="Meno" variant="outlined" />
                </div>
                <div>
                    <div>Email:</div>
                    <TextField required value={email} onChange={(event) => setEmail(event.target.value)} id="email" label="Email" variant="outlined" />
                </div>

                <div>
                    <h4>O čo sa zaujímate?</h4>
                    <FormGroup>
                        {signUpList && signUpList.map((row, index) => {
                            return (
                                <div key={row.id}>
                                    <FormControlLabel control={<Checkbox onChange={
                                        (event, checked) => {
                                            if (checked) {
                                                let value = signUpList?.filter(item => item.id === row.id);
                                                setUserPickedEvents(userPickedEvents?.concat(value));
                                            } else {
                                                // remove from array
                                                setUserPickedEvents(userPickedEvents?.filter(item => item.id !== row.id))
                                            }
                                        }
                                    } name={row.id?.toString()} defaultChecked size="small" />} label={row.name} />
                                </div>
                            )
                        })}
                    </FormGroup>
                </div>
                <div className='col-sm-3'><ReCAPTCHA
                    className="captcha"
                    sitekey="6LdqNXUfAAAAAFV6iX2pVeLy5q_so2tKsdbCXF6Y"
                    onChange={(value) => {
                        if (value != null) {
                            setReCaptchaToken(value);
                            setClickButtonDisabled(false);
                        }
                    }}
                    onExpired={() => {
                        setReCaptchaToken("");
                        setClickButtonDisabled(true);
                    }}
                /></div>
                <i className="bi bi-info-circle">
                    Odoslaním formulára vyjadrujete <a href="https://forms.gle/j2GkyALxJSdh4kxdA" target="_blank" rel="noreferrer">súhlas s GDPR.</a>
                </i>
                <div className="row">
                    <div className="col-sm float-left">
                        <Button onClick={saveContact} variant="outlined" disabled={clickButtonDisabled}>Uložiť</Button>
                    </div>
                    <div className="col-sm float-left">
                        <Button onClick={() => navigate("/intranet")} variant="outlined">Späť</Button>
                    </div>
                </div>

            </div>
            <MessageDialog data={confirmationDialogData} />
        </>
    );
};

export default SignUpPage;