import { Component } from "react";

type Props = { errorMessage: string };

export default class ErrorText extends Component<Props> {
    render() {
        return (
            <div className="form-group">
                <div className="alert alert-danger" role="alert">
                    {this.props.errorMessage}
                </div>
            </div>)
    }
}
