import { useLocation, useNavigate, useParams, } from "react-router-dom";
import React, { Component } from 'react';


let withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

function AuthVerify() {
  
  const MyComponent = () => {
    const location = useLocation()
  
    React.useEffect(() => {
      // runs on location, i.e. route, change
      const savedUser = localStorage.getItem("user");
        const user = JSON.parse(savedUser);
  
        if (user) {
          const decodedJwt = parseJwt(user.accessToken);
  
          if (decodedJwt.exp * 1000 < Date.now()) {
            localStorage.removeItem("user");
          }
        }
    }, [location]);
    
  }
  return (
      <div></div>
  );
};

export default withRouter(AuthVerify);



