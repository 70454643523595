import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import AuthService from "./services/auth.service";
import IUser from './types/user.type';
import Login from "./components/login.component";
import Register from "./components/register.component";
import Profile from "./components/profile.component";
import UserDashboard from "./components/userDashboard.component";
import BoardSMSAdmin from "./components/board-smsAdmin.component";
import EventBus from "./common/EventBus";
import AuthVerify from "./common/auth-verify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BoardLunchAdmin from "./components/lunch/board-lunchAdmin.component";
import AddRoleToUser from "./components-admin/addRoleToUser.component";
import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import SignUpPage from "./components-public/signUpPage.component";
import LunchOrder from "./components/lunch/lunchOrder.component";
import { Button, Fade, Menu, MenuItem } from '@mui/material';
import useUserRolesStore from "./stores/userRoles.store";
import { useTranslation } from "react-i18next";
import BesiedkaAdmin from "./components/besiedka/besiedka-admin.component";
import useSettingsStore from "./stores/settings.store";
import AdminBoard from "./components-admin/admin-board.component";

const loadStores = () => {
  useUserRolesStore.getState().load();
  useSettingsStore.getState().load();
};

function App() {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<'sk' | 'en'>('sk');

  const changeLanguage = useCallback(() => {
    if ('sk' === language) {
      setLanguage('en');
      i18n.changeLanguage('en');
    } else {
      setLanguage('sk');
      i18n.changeLanguage('sk');
    }
  }, [i18n, language, setLanguage]);

  const [currentUser, setCurrentUser] = useState<IUser | undefined>(AuthService.getCurrentUser());
  const [showSmsBoard, showLunchBoard, showAdminBoard, showBesiedkaAdminBoard] = useUserRolesStore(state => [state.SMS_ADMIN, state.LUNCH_ADMIN, state.SUPERADMIN, state.BESIEDKA_ADMIN]);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [lunch_module_enabled, sms_module_enabled] = useSettingsStore(state => [state.isSettingEnabled('Lunch_module_enabled'), state.isSettingEnabled('Sms_module_enabled')]);

  const handleMenuClick = (event: any) => {
    setAnchorMenu(event.currentTarget);
  };

  useLayoutEffect(() => {
    if (currentUser !== null) {
      loadStores();
    }
  }, [currentUser]);

  let logOut = () => {
    console.debug("App.tsx => logout");
    AuthService.logout();
    setCurrentUser(undefined);
  }

  let initValues = () => {
    console.debug("initValues");
    setCurrentUser(AuthService.getCurrentUser());
    loadStores();
  };

  EventBus.on("logout", logOut);
  EventBus.on("login", initValues);

  useEffect(() => {
    return () => {
      // Anything in here is fired on component unmount.
      EventBus.remove("logout", logOut);
      EventBus.remove("login", initValues);
    }
  }, []);

  return (
    <div>
      {currentUser && (
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Button onClick={handleMenuClick} className="navbar-brand">
            <i className="bi bi-list-ul"></i> {t('menu')}
          </Button>
          <div>
            <Menu
              id="simple-menu"
              anchorEl={anchorMenu}
              keepMounted
              open={Boolean(anchorMenu)}
              onClose={() => setAnchorMenu(null)}
              TransitionComponent={Fade}
            >
              {lunch_module_enabled && <MenuItem onClick={() => { navigate("/obed"); setAnchorMenu(null); }}>{t('order-lunch')}</MenuItem>}
              <MenuItem onClick={() => { navigate("/profile"); setAnchorMenu(null); }}>{t("profile")}</MenuItem>
              {sms_module_enabled && showSmsBoard && <MenuItem onClick={() => { navigate("/smsadmin"); setAnchorMenu(null); }}>{t('sms-board')}</MenuItem>}
              {lunch_module_enabled && showLunchBoard && <MenuItem onClick={() => { navigate("/obedadmin"); setAnchorMenu(null); }}>{t('insert-lunch')}</MenuItem>}
              {showAdminBoard && <MenuItem onClick={() => { navigate("/admin-board"); setAnchorMenu(null); }}>{t('admin-board')}</MenuItem>}
              {showBesiedkaAdminBoard && <MenuItem onClick={() => { navigate("/besiedka-admin"); setAnchorMenu(null); }}>{t('besiedka-admin-board')}</MenuItem>}

              <MenuItem onClick={logOut}>{t("logout")}</MenuItem>
            </Menu>
          </div>

          <img className={"language-flag " + (language === 'sk' ? ' sk-vlajka' : ' en-vlajka')} alt="" onClick={changeLanguage} />
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <a href="/" className="nav-link" onClick={logOut}>
                {t("logout")}
              </a>
            </li>
          </div>
        </nav>
      )}

      <div className="container mt-3">
        <Routes>
          <Route path="/" element={currentUser ? <UserDashboard /> : <Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/smsadmin" element={sms_module_enabled && showSmsBoard ? <BoardSMSAdmin /> : <Profile />} />
          <Route path="/obedadmin" element={lunch_module_enabled && showLunchBoard ? <BoardLunchAdmin /> : <Profile />} />
          <Route path="/obed" element={lunch_module_enabled ? <LunchOrder /> : <Profile />} />
          <Route path="/admin-board" element={showAdminBoard ? <AdminBoard /> : <Profile />} />
          {/* // TODO dorobit */}
          {/* <Route path="/role-menu" element={showAdminBoard ? <RoleMenu /> : <Profile />} /> */}
          <Route path="/besiedka-admin" element={showBesiedkaAdminBoard ? <BesiedkaAdmin /> : <UserDashboard />} />
          {/* -------------  verejene endpointy  ----------------*/}
          <Route path="/join-us" element={<Register />} />
          <Route path="/signup" element={<SignUpPage />} />
          {/* -------------  /verejene endpointy  ----------------*/}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>

      <AuthVerify logOut={logOut} />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;