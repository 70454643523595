import { Component, ReactInstance } from "react";
import { ActualLunchEntryData } from "../../types/Lunch.type";
import ReactToPrint from 'react-to-print';


type Props = { data: ActualLunchEntryData | undefined, backFn: any };

export class OrderedLunchlist extends Component<Props> {
    render() {
        var componentRef: ReactInstance | null;
        const dateOfLunch = new Date(this.props.data?.dateOfLunch ?? "").toLocaleString("sk");

        var sumOfOrders = this.props.data?.orders?.filter(obj => {
            return obj.lunchCount > 0
        }).reduce((partialSum, a) => partialSum + a.lunchCount, 0);

        return (
            <>
                <button
                    className="btn btn-primary btn-custom"
                    onClick={this.props.backFn}
                >
                    Späť</button>
                <ReactToPrint
                    documentTitle={"Zoznam stravníkov_" + dateOfLunch}
                    trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.                                            
                        return <button className="btn btn-primary btn-custom"> Tlač</button>;
                    }}
                    content={() => {
                        return componentRef;
                    }}
                />
                <div className="text-20" ref={el => (componentRef = el)}>
                    <h1 className="l-margin-5">Obedový hárok</h1>
                    <hr></hr>
                    <div className="l-margin-5">
                        {dateOfLunch}
                        <br />
                        {this.props.data?.lunchEntryText}
                        <br />
                        Objednané: {sumOfOrders},
                        Cena: {this.props.data?.lunchPrice && this.props.data?.lunchPrice / 100}€ ,
                        Suma: {this.props.data?.lunchPrice && sumOfOrders && sumOfOrders * this.props.data?.lunchPrice / 100}€
                    </div>

                    <hr></hr>
                    <span className="l-margin-5">Meno a priezvisko</span>
                    <span className="l-margin-5">Počet obedov</span>
                    <span className="l-margin-5">Zaplatené</span>
                    <span className="l-margin-11">Vyzdvihnuté</span>

                    {
                        this.props.data?.orders?.filter(obj => {
                            return obj.lunchCount > 0
                        }).map((row, index) => {
                            return (

                                <div key={index}>
                                    <span className="l-margin-5">{row.customerName}</span>
                                    <span className="l-margin-10">{row.lunchCount}</span>
                                    <input className="lunch-print-checkbox" type="checkbox"></input>
                                    <input className="lunch-print-checkbox" type="checkbox"></input>
                                </div>

                            )
                        })
                    }
                </div>
            </>
        );
    }
}