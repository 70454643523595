import { Component } from "react";

import SmsAdminService from "../services/smsAdmin.service";
import EventBus from "../common/EventBus";
import Contact from "../types/Contact.type";
import SmsGroup from "../types/Smsgroup.type";
import CreateEditContact from "./createEditContact.component";
import { Multiselect } from "multiselect-react-dropdown";
import ErrorText from "../components-common/errorText.component";
import ConfirmDialog, { ConfirmDialogData } from "../components-common/confirm-dialog";
import SentGroupSmsResponse from "../types/SentGroupSmsResponse.type";
import React from "react";
import AuthService from "../services/auth.service";
import IUser from "../types/user.type";

type Props = {};

type State = {
  manualImportError: string;
  manualImportResult: string;
  sendSmsError: string;
  searchContactError: string;
  smsGroupMgmtError: string;
  contacts: Array<Contact>;
  smsGroups: Array<SmsGroup>;
  searchInContacts: string;
  searchInSmsGroups: string;
  contactsLoading: boolean;
  isCreateEditContactVisible: boolean;
  currentlyEditedContact: Contact | undefined;
  currentlyEditedSmsGroup: SmsGroup;
  smsTextToSend: string;
  smsGroupToSend: SmsGroup | undefined;
  confirmationDialogData: ConfirmDialogData;
  sentSmsResponse: SentGroupSmsResponse;
  sendSmsButtonDisabled: boolean;
  manualImportRunning: boolean;
  currentUser: IUser | undefined;
}

let logoutOnUnauthorized = (error: any) => {
  if (error.response && error.response.status === 401) {    
    EventBus.dispatch("logout");
  }
}

export default class BoardSMSAdmin extends Component<Props, State> {
  multiselectRef: any;
  constructor(props: Props) {
    super(props);
    console.debug("sms admin");
    this.firesearchInContacts = this.firesearchInContacts.bind(this);
    this.createContact = this.createContact.bind(this);
    this.closeCreateEditPanel = this.closeCreateEditPanel.bind(this);
    this.changeSmsTextToSend = this.changeSmsTextToSend.bind(this);
    this.sendSmsToGroup = this.sendSmsToGroup.bind(this);
    this.deleteSmsGroup = this.deleteSmsGroup.bind(this);
    this.editSmsGroup = this.editSmsGroup.bind(this);
    this.saveCurrentlyEditedSmsGroup = this.saveCurrentlyEditedSmsGroup.bind(this);
    this.handleSmsgroupInputChange = this.handleSmsgroupInputChange.bind(this);
    this.loadSmsGroups = this.loadSmsGroups.bind(this);
    this.handleUpdateAfterContactSave = this.handleUpdateAfterContactSave.bind(this);
    this.editContact = this.editContact.bind(this);
    this.runManualImport = this.runManualImport.bind(this);

    this.multiselectRef = React.createRef();

    this.state = {
      manualImportRunning: false,
      manualImportResult: "",
      manualImportError: "",
      searchContactError: "",
      sendSmsError: "",
      smsGroupMgmtError: "",
      searchInContacts: "",
      searchInSmsGroups: "",
      contactsLoading: false,
      contacts: [],
      smsGroups: [],
      isCreateEditContactVisible: false,
      currentlyEditedContact: undefined,
      currentlyEditedSmsGroup: { smsgroup: "" },
      smsTextToSend: "",
      smsGroupToSend: undefined,
      confirmationDialogData: {} as ConfirmDialogData,
      sentSmsResponse: {},
      sendSmsButtonDisabled: false,
      currentUser: AuthService.getCurrentUser(),
    };
  }


  componentDidMount() {   
      if (this.state.currentUser === null || this.state.currentUser === undefined) {  
        // TODO find out how to redirect from class component or rewrite whole class to functions      
        // navigate("/");
      }
    this.loadSmsGroups();
  }

  loadSmsGroups() {
    SmsAdminService.getAllSmsGroups().then(
      (response: { data: SmsGroup[]; }) => {
        this.setState({ smsGroups: response.data as Array<SmsGroup> });
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        this.setState({
          smsGroupMgmtError:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        logoutOnUnauthorized(error);
      }
    );
  }

  firesearchInContacts() {
    const { searchInContacts, contactsLoading } = this.state;
    if (searchInContacts.trim().length < 3 || contactsLoading) {
      return;
    }
    // check if is empty string
    this.setState({ contactsLoading: true });

    // TODO set component masked
    SmsAdminService.searchContacts(searchInContacts).then(
      (response: { data: Contact[]; }) => {
        this.setState({ contacts: response.data as Array<Contact> });
        this.setState({ contactsLoading: false, searchContactError: "" });
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        this.setState({
          searchContactError:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
        this.setState({ contactsLoading: false });
        logoutOnUnauthorized(error);
      }
    );
  }

  editContact(contact: Contact) {
    // if already opened for creating, 
    if (this.state.isCreateEditContactVisible) {
      this.setState({
        isCreateEditContactVisible: false,
        currentlyEditedContact: undefined
      });
    }

    this.setState({
      currentlyEditedContact: contact,
      isCreateEditContactVisible: true,
    });
  }

  createContact() {
    if (!this.state.isCreateEditContactVisible) {
      this.setState({
        currentlyEditedContact: {},
        isCreateEditContactVisible: true,
      });
    } else {
      this.setState({
        currentlyEditedContact: undefined,
        isCreateEditContactVisible: false,
      });
    }
  }

  closeCreateEditPanel(resultStatus?: boolean) {
    this.setState({ isCreateEditContactVisible: false, currentlyEditedContact: undefined });

    // TODO show some info, if contact save was succesfull, or not


    // fire again search query
    if (this.state.searchInContacts.length > 0) {
      this.firesearchInContacts();
    }
  }

  deleteContact(contactId: Number) {
    SmsAdminService.deleteContact(contactId).then(
      (response: any) => {
        this.setState({ contacts: this.state.contacts.filter(obj => obj.id !== contactId) });
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        this.setState({
          searchContactError:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        logoutOnUnauthorized(error);
      }
    );
  }

  changeSmsTextToSend(event: React.ChangeEvent<HTMLTextAreaElement>) {
    var input = event.target.value;

    var combining = /[\u0300-\u036F]/g;
    input = input.normalize('NFKD').replace(combining, '');

    // eslint-disable-next-line
    const pattern = new RegExp(/^[\x00-\x7F]*$/);

    if (pattern.test(input)) {
      this.setState({ smsTextToSend: input });
    }
  }

  sendSmsToGroup() {
    this.setState({
      sendSmsError: "",
      sentSmsResponse: {}
    });

    SmsAdminService.sendSmsToGroup(this.state.smsTextToSend, this.state.smsGroupToSend?.id).then(
      (response: { data: SentGroupSmsResponse; }) => {
        this.setState({ sentSmsResponse: response.data as SentGroupSmsResponse, sendSmsButtonDisabled: false, smsTextToSend: "", smsGroupToSend: undefined });
        this.multiselectRef.current.resetSelectedValues();
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        this.setState({
          sendSmsError:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
        logoutOnUnauthorized(error);
      }
    );
  }

  editSmsGroup(entry: SmsGroup) {
    this.setState({ currentlyEditedSmsGroup: entry });
  }

  saveCurrentlyEditedSmsGroup() {
    if (this.state.currentlyEditedSmsGroup.smsgroup !== undefined && this.state.currentlyEditedSmsGroup.smsgroup?.length > 60) {
      this.setState({ smsGroupMgmtError: "Group name too long: max 60 chars." });
      return;
    }
    this.setState({ smsGroupMgmtError: "" });

    const reducedSmsGroup = {
      id: this.state.currentlyEditedSmsGroup.id || undefined,
      smsgroup: this.state.currentlyEditedSmsGroup.smsgroup || undefined,
    } as SmsGroup
    // TODO max 60 chars here
    SmsAdminService.addUpdateSmsGroup(reducedSmsGroup).then(
      (response: any) => {
        this.setState({ currentlyEditedSmsGroup: { smsgroup: "" } as SmsGroup });
        this.loadSmsGroups();
      },
      (error: { response: { status: string | number; data: string; } | null; toString: () => string; }) => {
        this.setState({
          smsGroupMgmtError:
            (error.response != null ? error.response.status + ' ' + error.response.data : error.toString())
        });
        logoutOnUnauthorized(error);
      }
    );
  }

  deleteSmsGroup(smsGroup: SmsGroup) {
    this.setState({
      smsGroupMgmtError: ""
    });

    SmsAdminService.deleteSmsGroup(smsGroup.id).then(
      (response: any) => {
        this.loadSmsGroups();
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        this.setState({
          smsGroupMgmtError:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
        logoutOnUnauthorized(error);
      }
    );
  }

  handleSmsgroupInputChange(smsGroupName: string) {
    const smsGroup = {
      id: this.state.currentlyEditedSmsGroup?.id || undefined,
      smsgroup: smsGroupName,
      count: this.state.currentlyEditedSmsGroup?.count || undefined,
    } as SmsGroup;

    this.setState({ currentlyEditedSmsGroup: smsGroup });
  }

  handleUpdateAfterContactSave() {
    if (this.state.searchInContacts.length > 0) {
      console.log("Search string present, fire queries.");
      this.firesearchInContacts();
      this.loadSmsGroups();
    }
  }

  runManualImport() {
    this.setState({ manualImportResult: "", manualImportError: "", manualImportRunning: true });

    SmsAdminService.runManualImport().then(
      (response: { data: any; }) => {
        this.setState({ manualImportResult: response.data });
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        this.setState({
          manualImportError:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
        this.setState({ contactsLoading: false });
        logoutOnUnauthorized(error);
      }
    ).finally(() => {
      this.setState({ manualImportRunning: false });
    });
  }

  render() {
    const { sendSmsError, searchContactError, smsGroupMgmtError,
      manualImportError, manualImportResult, manualImportRunning,
      smsGroups, searchInContacts, searchInSmsGroups,
      contacts, contactsLoading, isCreateEditContactVisible,
      currentlyEditedContact, sentSmsResponse,
      sendSmsButtonDisabled, currentlyEditedSmsGroup, confirmationDialogData, currentUser } = this.state;
    return (
      <>
        {currentUser && (
          <>
            <div className="send-sms-area">
              <label>Choose sms gruop:</label>
              <Multiselect
                options={smsGroups} // Options to display in the dropdown
                selectedValues={[]} // Preselected value to persist in dropdown
                onSelect={(list, item) => (this.setState({ smsGroupToSend: item as SmsGroup }))} // Function will trigger on select event
                onRemove={(list, item) => (this.setState({ smsGroupToSend: undefined }))} // Function will trigger on remove event
                displayValue="smsgroup" // Property name to display in the dropdown options
                placeholder="Sms group to send sms"
                singleSelect={true}
                ref={this.multiselectRef}
              />
              <div>Write here sms to send(5-160 chars): [{this.state.smsTextToSend.length}]</div>
              <div>
                <textarea
                  className="send-sms-textarea"
                  readOnly={sendSmsButtonDisabled}
                  value={this.state.smsTextToSend}
                  onChange={this.changeSmsTextToSend}
                  maxLength={160}
                  minLength={5}
                  rows={4}
                ></textarea>
              </div>

              <button
                onClick={() => this.setState({
                  confirmationDialogData: {
                    title: "Send sms confirmation",
                    body: "Confirm sms to: " + this.state.smsGroupToSend?.smsgroup + " with " + this.state.smsGroupToSend?.count + " members.",
                    onClose: () => this.setState({ confirmationDialogData: {} as ConfirmDialogData }),
                    onSubmit: () => this.sendSmsToGroup()
                  }
                })}
                disabled={sendSmsButtonDisabled || this.state.smsGroupToSend === undefined || this.state.smsTextToSend.length < 5}
                className="btn btn-success btn-block"
              >Send sms</button>

              {(Object.keys(sentSmsResponse).length > 0) && (
                <div className="alert alert-success sentsms-info margin-bottom-5">
                  <div>Sent sms: {sentSmsResponse?.successSmsCount}</div>
                  <div>Not sent: {sentSmsResponse?.failedSmsCount}</div>
                </div>
              )}

              {(sentSmsResponse) && (sentSmsResponse.failedSmsInfo) && (sentSmsResponse?.failedSmsInfo?.length > 0) && (
                <div className="alert alert-danger margin-bottom-5">Failed sms info:
                  {sentSmsResponse.failedSmsInfo.map((row, index) => {
                    return (<div key={index}> {row}</div>)
                  })}
                </div>
              )}
              {sendSmsError && (<ErrorText errorMessage={sendSmsError} />)}
            </div>

            <div className="contact-search-area">
              <div>Search in Contacts: name, surname, email, phone, sms group name(min 3 chars)</div>
              <input
                type="search"
                name="search-contacts-form"
                id="search-contacts-form"
                className="search-input"
                placeholder="Search in contacts"
                value={searchInContacts}
                onChange={(e) => this.setState({ searchInContacts: e.target.value.trim() })}
                onKeyPress={event => { if (event.key === 'Enter') { this.firesearchInContacts(); } }}
              />
              <button
                onClick={this.firesearchInContacts}
                disabled={this.state.searchInContacts.trim().length < 3 || contactsLoading}
                className="mx-2 btn btn-primary"
              >
                Search
              </button>

              {contacts.length > 0 && (<div>firstname | lastname | email | phone | sms groups</div>)}
              {contacts && (
                contacts.map(each => {
                  return (<div key={each.id}>
                    {each.firstname ?? "no value"} | {each.lastname ?? "no value"} | {each.email ?? "no value"} | {each.phone ?? "no value"} | {each.smsgroups?.map(group => { return (<span key={group.id}>{group.smsgroup} | </span>) })}
                    <button className="btn btn-success btn-custom" onClick={() => this.editContact(each)} >Edit</button>
                    <button
                      className="btn btn-danger btn-custom"
                      onClick={() => this.setState({
                        confirmationDialogData: {
                          title: "Contact removal",
                          body: "Confirm to remove selected contact.",
                          onClose: () => this.setState({ confirmationDialogData: {} as ConfirmDialogData }),
                          onSubmit: () => this.deleteContact(each.id)
                        }
                      })}>Delete</button>
                  </div>)
                })
              )
              }
              {searchContactError && (<ErrorText errorMessage={searchContactError} />)}
            </div>

            <div className="sheets-manual-import-area">
              <button onClick={this.runManualImport} className="mt-1 mx-2 btn btn-success" disabled={manualImportRunning}>
                Run manual contacts import!
              </button>
              (Regular contacts import is done every 2,5 hours.)
              {manualImportResult && (<div className="alert alert-success sentsms-info margin-bottom-5">
                <div>Result: {manualImportResult}</div>
              </div>)}
              {manualImportError && (<ErrorText errorMessage={manualImportError} />)}
            </div>

            <div className="contact-managment-area">
              <div className="text-center">
                <button onClick={this.createContact} className="mt-1 btn btn-primary">
                  Create contact
                </button>
              </div>

              {isCreateEditContactVisible && (
                <CreateEditContact
                  updateSearchResults={this.handleUpdateAfterContactSave}
                  contact={currentlyEditedContact}
                  smsGroups={smsGroups}
                  closeCreateEditPanel={this.closeCreateEditPanel}
                />)}
            </div>

            <div className="smsgroups-managment-area">
              <div>
                Search in sms groups: [can delete only empty group]
                <div>
                  <input
                    type="search"
                    name="search-form"
                    id="search-form"
                    className="search-input"
                    placeholder="Search for..."
                    value={searchInSmsGroups}
                    onChange={(e) => this.setState({ searchInSmsGroups: e.target.value.trim() })}
                  />
                </div>
              </div>
              {smsGroups && (
                smsGroups.filter(item => item.smsgroup?.toLocaleLowerCase().includes(searchInSmsGroups.toLocaleLowerCase()))
                  .map(each => {
                    return (
                      <div key={each.id}>
                        <span className="smsgroup-data"> {each.smsgroup} {each.count}</span>
                        <button className="btn btn-success btn-custom" onClick={() => this.editSmsGroup(each)} >Edit</button>
                        <button className="btn btn-danger btn-custom" onClick={() => {
                          if (each.count !== 0) {
                            this.setState({
                              smsGroupMgmtError: "Can't delete non-empty sms group: " + each.smsgroup
                            });
                            return;
                          }
                          this.setState({
                            smsGroupMgmtError: "",
                            confirmationDialogData: {
                              title: "Sms group removal",
                              body: "Confirm to remove " + each.smsgroup + ".",
                              onClose: () => this.setState({ confirmationDialogData: {} as ConfirmDialogData }),
                              onSubmit: () => this.deleteSmsGroup(each)
                            }
                          })
                        }
                        }>Delete</button>
                      </div>)
                  })
              )}
              <div>
                <label>{currentlyEditedSmsGroup?.smsgroup ? "Edit" : "Create"} sms group(min 4 chars):</label>
                <input
                  type="text"
                  name="edit-smsgroup"
                  id="edit-smsgroup"
                  className=""
                  placeholder="Create/edit sms group"
                  value={this.state.currentlyEditedSmsGroup.smsgroup}
                  onChange={(e) => this.handleSmsgroupInputChange(e.target.value)}
                />
                <button
                  className="mx-2 btn btn-primary"
                  disabled={
                    this.state.currentlyEditedSmsGroup?.smsgroup === undefined ||
                    this.state.currentlyEditedSmsGroup?.smsgroup?.trim().length < 4 ||
                    this.state.currentlyEditedSmsGroup?.smsgroup?.trim().length > 60
                  }
                  onClick={() => this.saveCurrentlyEditedSmsGroup()} >Save</button>
              </div>
              {smsGroupMgmtError && (<ErrorText errorMessage={smsGroupMgmtError} />)}
            </div>
            <ConfirmDialog data={confirmationDialogData} />
          </>
        )}
      </>
    );
  }
}
