import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from 'react';
import IUser from "../types/user.type";
import useUserRolesStore from "../stores/userRoles.store";
import { useTranslation } from "react-i18next";
import useSettingsStore from "../stores/settings.store";
import AddRoleToUser from "./addRoleToUser.component";
import { Button, Checkbox } from "@mui/material";

const ManageAppSettings = () => {
    const [currentUser, setCurrentUser] = useState<IUser | undefined>(AuthService.getCurrentUser());
    const [showAdminBoard] = useUserRolesStore(state => [state.SUPERADMIN]);
    const [appSettings, saveSettings, setSetting] = useSettingsStore(state => [state.settings, state.saveSettings, state.setSetting]);

    const onSettingUpdateClick = useCallback((name: string, value: string) => {
        setSetting(name, value);
    }, [setSetting]);

    const { t } = useTranslation();

    let navigate = useNavigate();

    useEffect(() => {
        if (null == currentUser) { navigate("/") };
    });

    return (
        <div className=" red-bordered-area container">
            <div className="row">
                {showAdminBoard && (
                    <>
                        App Settings:
                        <ul>
                            {appSettings &&
                                appSettings.map((setting, index) =>
                                    <li key={index}>{setting.name}:
                                        <Checkbox
                                            checked={setting.value === 'true' ? true : false}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                onSettingUpdateClick(setting.name, new Boolean(event.target.checked).toString());
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </li>)}
                        </ul>
                        <Button onClick={saveSettings} variant="outlined">{t('save')}</Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default ManageAppSettings;