import { Component } from "react";
import "./board-lunchAdmin.component.scss";
import LunchAdminService from "../../services/lunchAdmin.service"
import { LunchEntry, ActualLunchEntryData, CustomerOrder } from "../../types/Lunch.type";
import EventBus from "../../common/EventBus";
import ConfirmDialog from "../../components-common/confirm-dialog";
import { ConfirmDialogData } from "../../types/ConfirmDialogData.type";
import { OrderedLunchlist } from "./page-orderedLunchlist.component";
import memoizeOne from 'memoize-one';
import IUser from "../../types/user.type";
import AuthService from "../../services/auth.service";
import { MyCustomCalendar } from "../../components-common/MyCustomCalendar";

const CountLunchOrders = (input: Array<CustomerOrder>) => {
  return input?.filter(obj => {
    return obj.lunchCount > 0
  }).reduce((partialSum, a) => partialSum + a.lunchCount, 0);
}
// TODO remove this one after switching to functions
const memoizedCountLunchOrders = memoizeOne(CountLunchOrders);

type Props = {};

type State = {
  newLunchEntryButtonDisabled: boolean;
  newLunchEntry: LunchEntry;
  lunchErrorText: string;
  actualLunchEntryData: Array<ActualLunchEntryData>;
  lunchHistoryEntries: Array<ActualLunchEntryData>;
  confirmationDialogData: ConfirmDialogData;
  lunchListIsPrinting: boolean;
  lunchToPrint: ActualLunchEntryData | undefined;
  currentUser: IUser | undefined;
}

const ToLocaleDateFn = (input: Date) => {
  return new Date(input).toLocaleString("sk")
}

let logoutOnUnauthorized = (error: any) => {
  if (error.response && error.response.status === 401) {
    EventBus.dispatch("logout");
  }
}

export default class BoardLunchAdmin extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.addNewLunchEntry = this.addNewLunchEntry.bind(this);
    this.lunchEntrytextChanged = this.lunchEntrytextChanged.bind(this);
    this.loadActualLunchWithInfo = this.loadActualLunchWithInfo.bind(this);
    this.deleteLunchEntry = this.deleteLunchEntry.bind(this);
    this.loadHistoryLunchWithInfo = this.loadHistoryLunchWithInfo.bind(this);
    this.switchLunchPagePrinting = this.switchLunchPagePrinting.bind(this);
    this.lunchPriceChanged = this.lunchPriceChanged.bind(this);
    this.freeLunchesChanged = this.freeLunchesChanged.bind(this);

    this.state = {
      newLunchEntryButtonDisabled: false,
      newLunchEntry: {} as LunchEntry,
      actualLunchEntryData: [] as Array<ActualLunchEntryData>,
      lunchHistoryEntries: [] as Array<ActualLunchEntryData>,
      lunchErrorText: "",
      confirmationDialogData: {} as ConfirmDialogData,
      lunchListIsPrinting: false,
      lunchToPrint: undefined,
      currentUser: AuthService.getCurrentUser(),
    };
  }

  loadActualLunchWithInfo() {
    LunchAdminService.getActualLunchWithInfo().then(
      (response: { data: ActualLunchEntryData[]; }) => {
        this.setState({ actualLunchEntryData: response.data as Array<ActualLunchEntryData> });
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        this.setState({
          lunchErrorText:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
        logoutOnUnauthorized(error);
      }
    )
  }

  loadHistoryLunchWithInfo() {
    //load history
    LunchAdminService.getHistoryLunchEntries().then(
      (response: { data: ActualLunchEntryData[]; }) => {
        this.setState({ lunchHistoryEntries: response.data as Array<ActualLunchEntryData> });
      },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        this.setState({
          lunchErrorText:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
        logoutOnUnauthorized(error);
      }
    );

  }

  componentDidMount() {
    // load actual entry
    this.loadActualLunchWithInfo();

    //load history entries
    this.loadHistoryLunchWithInfo();
  }

  addNewLunchEntry() {
    if (this.state.newLunchEntry?.lunchEntryText === undefined || this.state.newLunchEntry?.lunchEntryText?.length < 5) {
      return;
    }
    this.setState({ newLunchEntryButtonDisabled: true });

    LunchAdminService.addLunchEntry(this.state.newLunchEntry).then((response: any) => {
      //TODO show green sentence -> lunch added
    },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        this.setState({
          lunchErrorText:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
        logoutOnUnauthorized(error);
      }).finally(() => {
        this.setState({ newLunchEntryButtonDisabled: false, newLunchEntry: {} as LunchEntry });
        this.loadActualLunchWithInfo();
        //TODO empty all input here:
        this.inputLunchPrice.value = "";
        this.inputFreeLunches.value = "";
        this.textareaLunchEntryText.value = "";
      });
  }

  lunchEntrytextChanged(event: React.ChangeEvent<HTMLTextAreaElement>) {
    var input = event.target.value;

    this.setState(prevState => ({
      newLunchEntry: {
        ...prevState.newLunchEntry,
        lunchEntryText: input
      }
    }))
  }

  deleteLunchEntry(lunchEntryId: number) {
    if (lunchEntryId === null || lunchEntryId === undefined) {
      return;
    }
    LunchAdminService.deleteLunchEntry(lunchEntryId).then((response: any) => {
      console.log("lunch entry deleted");
    },
      (error: { response: { data: { message: any; }; status: number; }; message: any; toString: () => any; }) => {
        this.setState({
          lunchErrorText:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
        logoutOnUnauthorized(error);
      }).finally(() => {
        this.loadActualLunchWithInfo();
      });
  }

  switchLunchPagePrinting() {
    this.setState({ lunchListIsPrinting: !this.state.lunchListIsPrinting });
  }

  lunchPriceChanged(event: React.ChangeEvent<HTMLInputElement>) {
    var price = event.target.value;

    const finalPriceInCents = Math.trunc(+price * 100);

    this.setState(prevState => ({
      newLunchEntry: {
        ...prevState.newLunchEntry,
        lunchPrice: finalPriceInCents
      }
    }))
  }

  freeLunchesChanged(event: React.ChangeEvent<HTMLInputElement>) {
    var freeLunches = event.target.value;

    this.setState(prevState => ({
      newLunchEntry: {
        ...prevState.newLunchEntry,
        freeLunchesOrdering: +freeLunches
      }
    }))
  }
  inputLunchPrice: any;
  inputFreeLunches: any;
  textareaLunchEntryText: any;

  render() {
    const { currentUser, newLunchEntryButtonDisabled, lunchErrorText, lunchHistoryEntries, actualLunchEntryData, confirmationDialogData, lunchListIsPrinting, lunchToPrint } = this.state;

    return (
      <>
        {currentUser && (
          <>

            <div hidden={lunchListIsPrinting} className="lunch-page">
              <div className="new-lunch-area">
                Menu: [{this.state.newLunchEntry?.lunchEntryText?.length || 0}/560]
                <div>
                  <textarea
                    ref={el => this.textareaLunchEntryText = el}
                    className="lunch-entry-textarea"
                    readOnly={newLunchEntryButtonDisabled}
                    value={this.state.newLunchEntry?.lunchEntryText}
                    onChange={this.lunchEntrytextChanged}
                    maxLength={560}
                    minLength={5}
                    rows={4}
                  ></textarea>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div>
                      Kedy je ponuka platna:
                      <MyCustomCalendar dateSelected={(date: Date) => this.setState(prevState => ({
                        newLunchEntry: {
                          ...prevState.newLunchEntry,
                          dateOfLunch: date
                        }
                      }))} ></MyCustomCalendar>
                    </div>
                    <div >
                      Dokedy treba obed objednat:
                      <MyCustomCalendar dateSelected={(date: Date) => this.setState(prevState => ({
                        newLunchEntry: {
                          ...prevState.newLunchEntry,
                          orderUntilDate: date
                        }
                      }))}></MyCustomCalendar>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div >
                      <div >
                        Cena za obed:
                      </div>
                      <input
                        ref={el => this.inputLunchPrice = el}
                        onChange={this.lunchPriceChanged}
                        max={100}
                        placeholder="€"
                        type="number" name="lunchPrice" min={0} />
                    </div>
                    <div>
                      <div >
                        Počet voľných obedov:
                      </div>
                      <input
                        ref={el => this.inputFreeLunches = el}
                        onChange={this.freeLunchesChanged}
                        placeholder="ks"
                        type="number" name="lunchesFree" min={0} />
                    </div>
                  </div>
                </div>


                <button
                  onClick={this.addNewLunchEntry}
                  disabled={
                    newLunchEntryButtonDisabled || Object.keys(this.state.newLunchEntry).length === 0 || this.state.newLunchEntry?.lunchEntryText?.length < 5
                  }
                  className="btn btn-success btn-block"
                >Pridaj ponuku</button>
              </div>


              <div className="actual-lunch-area">
                <span className="row">
                  <span className="col-sm-6">Otvorene ponuky obedov:</span>
                  <span className="col-sm-2">Kedy?</span>
                  <span className="col-sm-1">Otvorené?</span>
                  <span className="col-sm-1">Objednané:</span>
                  <span className="col-sm-2">Akcia</span>
                </span>
                {actualLunchEntryData && actualLunchEntryData.length > 0 && actualLunchEntryData?.map((row, index) => {
                  return (<div key={index} className="row">
                    <span className="col-sm-6">{row.id} {row.lunchEntryText}</span>
                    <span className="col-sm-2">{ToLocaleDateFn(row.dateOfLunch)}</span>
                    <span className="col-sm-1 cenetered-text bold">{new Date(row.orderUntilDate) > new Date() ? "Áno" : "Nie"}</span>
                    <span className="col-sm-1 cenetered-text bold">{memoizedCountLunchOrders(row.orders)}
                    </span>
                    <span className="col-sm-2">
                      <button
                        className="btn btn-primary btn-custom"
                        onClick={() => {
                          this.switchLunchPagePrinting();
                          this.setState({ lunchToPrint: row })
                        }}
                      > Sumár</button>

                      <button
                        onClick={() => this.setState({
                          confirmationDialogData: {
                            title: "Vymazanie ponuky obedu",
                            body: "Kliknutím potvrďte vymazanie: " + row.lunchEntryText,
                            onClose: () => this.setState({ confirmationDialogData: {} as ConfirmDialogData }),
                            onSubmit: () => this.deleteLunchEntry(row.id)
                          }
                        })}
                        className="btn btn-danger btn-custom">
                        Vymazať
                      </button>
                    </span>
                  </div>
                  )
                })}
              </div>

              <div className="history-lunch-area">
                <span className="row">
                  <span className="col-sm-6">Ukoncene ponuky obedov:[mesiac dozadu, max 5]</span>
                  <span className="col-sm-2">Kedy?</span>
                  <span className="col-sm-1">Objednané:</span>
                  <span className="col-sm-2">Akcia</span>
                </span>
                {lunchHistoryEntries && lunchHistoryEntries.length > 0 && lunchHistoryEntries?.map((row, index) => {
                  return (
                    <div key={index} className="row">
                      <span className="col-sm-6">{row.id} {row.lunchEntryText}</span>
                      <span className="col-sm-2">{ToLocaleDateFn(row.dateOfLunch)}</span>
                      <span className="col-sm-1">{memoizedCountLunchOrders(row.orders)}</span>
                      <span className="col-sm-2">
                        <button
                          className="btn btn-primary btn-custom"
                          onClick={() => {
                            this.switchLunchPagePrinting();
                            this.setState({ lunchToPrint: row })
                          }}
                        > Sumár</button>
                      </span>
                    </div>
                  )
                })}
              </div>
              {lunchErrorText}
              <ConfirmDialog data={confirmationDialogData} />
            </div>
            <div hidden={!lunchListIsPrinting} >
              <OrderedLunchlist data={lunchToPrint} backFn={this.switchLunchPagePrinting} />
            </div>
          </>
        )}
      </>
    );
  }
}
